@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
:root {
  --color-main: #fff;
  --color-background: #d9d9d9;
  --color-background-2: #F9FAFD;
  --color-black: #000;
  --color-black-2: #DAE0EB;
  --color-black-3: #AEB9CB;
  --color-black-4: #51607B;
  --color-black-5: #99A4B8;
  --color-black-label: #3f3f3f;
  --color-header-employee: #08142d;
  --color-label-employee: #51607b;
  --color-info-employee: #293755;
  --color-option-employee: #293755;
  --color-option-employee-hover: #1a9ed0;
  --color-placeHolder-employee: #aeb9cb;
  --color-placeHolder-project: #b0b0b0;
  --bg-form-employee: #eff3f9;
  --bg-form-employee-gray: rgba(0, 0, 0, 0.5);
  --bg-line-employee: #dae0eb;
  --bg-option-employee: #eff3f9;
  --fill-avatar: #f9fafd;
  --border-input-employee: #dae0eb;
  --linear-gradient-employee: linear-gradient(
    259deg,
    #1addd2 0%,
    #0094cc 100.81%
  );
  --linear-gradient-red: linear-gradient(259deg, #f43933 0%, #ef1414 100.81%);
  --linear-gradient-excel: linear-gradient(84deg, #0ab597 0%, #0bd78e 100%);
  --box-shadow-employ: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  --color-boder: #e1e1e1;
  --color-backgroundmain: #eff3f9;
  --color-secondary: #1a9ed0;
  --color-secondary2: #308add;
  --color-button: linear-gradient(90deg, #0094cc 0%, #1addd2 100%);
  --color-gradient-excel: linear-gradient(84deg, #0ab597 0%, #0bd78e 100%);
  --color-text-unclick: #aeb9cb;
  --color-sidermain: #1a2443;
  --color-sidersecondary: #111a33;
  --Text-color-Disable: #aeb9cb;
  --text-red-employee: #ef2e14;
  --text-gray-form: #c8c8c8;
  --Line: #dae0eb;
  --color-status-employ: #11ce5d;
  --bg-status-success: #e2fff5;
  --bg-status-error: #fff1f1;
  --text-color-Subtittle: #51607b;
  --filter-red: invert(29%) sepia(100%) saturate(7452%) hue-rotate(358deg)
    brightness(106%) contrast(108%);
  --bg-green-1: #e9fff2;
  --bg-red-1: #fff0f0;
}
body {
  ::-webkit-scrollbar {
    height: 8px;
    width: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #1a9ed0;
    border-radius: 10px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #f9fafd;
  }
}
* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
.text-liner {
  background: -webkit-linear-gradient(90deg, #0094cc 0%, #1addd2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-unclick {
  color: #8c97b6;
}

.pagination-custom {
  display: flex;
  align-items: center;
  gap: 6px;
  list-style: none;
}

.pagination-custom li {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--border-input-employee);
  overflow: hidden;
}

.pagination-custom li a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--color-label-employee);
  padding: 6px 11px 6px 12px;
}

.pagination-custom li.active {
  background-color: var(--color-option-employee-hover);
}

.pagination-custom li.active a {
  color: var(--color-main);
}

.datePickerStyle {
  width: 100%;
  border: 0.5px solid var(--bg-line-employee);
  border-radius: 8px;
  padding: 13px 12px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}

.datePickerStyle:focus {
  border-color: var(--color-option-employee-hover);
  outline: none;
}

.truncated {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  max-width: 180px;
}

.custom-radio-blue[data-checked] {
  border: 2px solid #1a9ed0 !important;
  border-color: #1a9ed0 !important;
  background-color: white !important;
}

.custom-radio-blue[data-checked]::before {
  content: "";
  width: 10px !important;
  height: 10px !important;
  background-color: #1a9ed0 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.colorDisableInput {
  color: var(--bg-line-employee);
}

.css-r6z5ec {
  z-index: 2 !important;
}

.pac-container {
  z-index: 10003 !important;
}

[data-rmiz-modal-overlay="visible"] {
  background: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(6px);
  transition: background 0.3s ease;
}

@keyframes shakePause {
  0%, 5% {
    transform: rotate(0deg);
  }
  8% {
    transform: rotate(-10deg);
  }
  12% {
    transform: rotate(10deg);
  }
  16% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(0deg);
  }
  26%, 100% {
    transform: rotate(0deg);
  }
}


